import React, { useContext, useEffect, useState } from 'react';
import { createContext } from 'react';
import { ChatMode, ETicketChannel } from './types';
import { fetchAllTicketsByEmail, fetchAllTicketsByUser, fetchChatHistoryByConversationId, fetchSelfUser, makeACreateConversation, makeASendAttachmentByUser, makeASendChatMessageByEmail, makeASendChatMessageByUser, makeASendTicketMessageByEmail, makeASendTicketMessageByUser, signInWithGoogle, unsubscribeToNotifications } from './api';
import { SocketContext } from '../Socket/SocketContext';
import axios from 'axios';
import { EStateGeneric, sleep } from '../../utils/general';

import { v4 } from "uuid"
import { EUserTags } from '../users/types';
import { googleLogout, useGoogleOneTapLogin } from '@react-oauth/google';

interface IChatUserData {
    id?: number
    fullName?: string
    email: string
    avatar: any
    tags: EUserTags[]
}

export interface IChatPayload {
    mode: string
    ticketId: string | null
    user: Partial<IChatUserData> | null
    conversationId: string | null
    isOpen: null | boolean
    actionPage: EActionPage | null
}

interface IMessagePayload {
    text: string
    options?: any[]
}

export interface IMessage {
    payload: IMessagePayload
    type: "text" | "choices"
    direction: "outgoing" | "incoming"
    createdAt: Date
    temporalId?: string
    status?: EStateGeneric
    senderName: string
}

export enum EActionPage {
    CONFIRM_REGISTRATION_EMAIL = "CONFIRM_REGISTRATION_EMAIL",
    LOGIN_SCREEN = "LOGIN_SCREEN",
    TWO_FACTOR_TO_APP = "TWO_FACTOR_TO_APP",
    TWO_FACTOR_SIMPLE = "TWO_FACTOR_SIMPLE"
}

const initialValues = {
    mode: ChatMode.HOME,
    ticketId: null,
    conversationId: null,
    isOpen: false,
    user: null,
    actionPage: null,
}


type FunctionAL = () => Promise<void>




interface ICountry {
    id: string
    iso: string
    name: string
}

interface IChatContext extends IChatPayload {
    updateValues(values: Partial<IChatPayload>): void
    loading: boolean
    messages: IMessage[]
    isLogged: boolean
    answer: string | null
    setAnswer(x): void
    setMessages(x): void
    tickets: any[]
    setTickets(x): void
    setLoading(x): void
    sendFileByWS(x, file): void
    sendMessageByWS(x: string): void
    logout(): void
    onAfterLogging?: null | FunctionAL
    setOnAfterLogging: any
    showLoader: boolean
    setShowLoader: Function
    targetCountry: ICountry
    countries: ICountry[]
}


export const ChatContext = createContext<IChatContext>({} as any);

export const ChatProvider = ({ children, targetCountry, countries }) => {

    const [values, setValues] = useState<IChatPayload>(initialValues)
    const [showLoader, setShowLoader] = useState(false)
    const [answer, setAnswer] = useState(null)
    const [tickets, setTickets] = useState([])

    const [onAfterLogging, setOnAfterLogging] = useState<any>(null)

    const isLogged = !!values.user

    const [messages, setMessages] = useState<IMessage[]>([])
    const [loading, setLoading] = useState(false)
    const updateValues = (_values: Partial<IChatPayload>) => {
        setValues(values => {
            const aux = ({
                ...values,
                ..._values,
            }) as any
            localStorage.setItem("chatData", JSON.stringify({
                ...aux,
                actionPage: ""
            }))
            return aux
        })

    }

    useGoogleOneTapLogin({
        onSuccess: async tokenResponse => {
            try {
                console.log(tokenResponse)
                const response = await signInWithGoogle({
                    credential: tokenResponse.credential,
                    targetCountryId: targetCountry?.id
                })
                const user = response?.data?.user

                if (user?.tags?.includes(EUserTags.TWO_FACTOR_AUTH)) {
                    updateValues({ user: response?.data?.user, actionPage: EActionPage.TWO_FACTOR_TO_APP })
                } else {
                    updateValues({ user: response?.data?.user, actionPage: null })

                    window.location.href = String(process.env.GATSBY_APP_URL)
                }
                console.log(response?.data?.user)
                console.log(response)
            } catch (error) {
                console.log(error)
                googleLogout()
            }

            /* if (signInWithGoogle.fulfilled.match(resultAction)) {
                if (
                    resultAction?.payload.user?.tags?.includes(EUserTags.TWO_FACTOR_AUTH)
                ) {
                    setIsFlipped(true);
                } else {
                    onSuccess()
                }
            } */
        },
        onError: () => {
            console.log('Login Failed');
        },
        disabled: !!values.user || loading,
        cancel_on_tap_outside: false,

    });


    const logout = async () => {


        updateValues({ user: null } as any)
        if ("serviceWorker" in navigator) {
            const registrations = await navigator.serviceWorker.getRegistrations();
            const endpoints = [];

            for (let i = 0; i < registrations.length; i++) {
                const registration = await registrations[
                    i
                ].pushManager.getSubscription();

                if (!registration) continue;
                endpoints.push(registration.endpoint);

                await registration.unsubscribe();
            }
            await unsubscribeToNotifications(endpoints);
        }
        try {
            await axios.post(
                `${process.env.GATSBY_API_URL}/auth/logout`,
                {},
                { withCredentials: true }
            )
        } catch (error) { }
        try {
            await googleLogout()
        } catch (error) {

        }

    }

    const sendFileByWS = async (e, field) => {
        e.preventDefault();

        let file = e.target.files[0];

        console.log({ file })

        if (!file) {
            return
        }

        const temporalId = v4()
        setMessages(messages => [{
            payload: {
                text: "[Subiendo adjunto]"
            },
            type: "text",
            direction: "incoming",
            createdAt: new Date(),
            temporalId,
            status: EStateGeneric.PENDING,
            senderName: "Cliente"
        }, ...messages])



        if (values?.mode === ChatMode.SUPPORT_AGENT && values.ticketId) {



            try {
                if (!values.user?.id) {
                    throw new Error("Ha sucedido un error. Sin usuario de Autogestiones")
                }
                const response = await makeASendAttachmentByUser(values.ticketId, file)

                console.log(response.data)
                const attachment = response?.data?.message?.attachments[0]
                // await sleep(1000)
                setMessages(messages => messages.map(x => x.temporalId === temporalId ? ({
                    ...x,
                    payload: {
                        text: convertLinksToAnchors(`[Adjunto] "${attachment.name}"\n ${attachment.type}\nURL: ${attachment.url}`)
                    },
                    id: response.data?.message?.id, status: EStateGeneric.SUCCEEDED
                }) : x))
            } catch (err) {
                console.error(err.response || err)
                setMessages(messages => messages.map(x => x.temporalId === temporalId ? ({ ...x, status: EStateGeneric.FAIL }) : x))

            }
        }


        field.current.value = "";

    }

    const sendMessageByWS = async (text: string) => {


        if (!text)
            return

        const temporalId = v4()

        setMessages(messages => [{
            payload: {
                text
            },
            type: "text",
            direction: "incoming",
            createdAt: new Date(),
            temporalId,
            status: EStateGeneric.PENDING,
            senderName: "Cliente"
        }, ...messages])

        try {

            if (!values.user) {
                throw new Error("Ha sucedido un error")
            }

            if (values?.mode === ChatMode.CHATBOT) {

                let conversationId = values.conversationId
                if (!conversationId) {
                    const response = await makeACreateConversation()
                    conversationId = response.data?.conversation.id
                }

                if (!conversationId)
                    return

                updateValues({
                    conversationId
                })


                const response = values.user?.id ? await makeASendChatMessageByUser(conversationId, text) : await makeASendChatMessageByEmail(conversationId, text, values.user?.email)
                console.log("respuesta")
                console.log(response.data)
                setMessages(messages => messages.map(x => x.temporalId === temporalId ? ({ ...x, id: response.data?.message?.id, status: EStateGeneric.SUCCEEDED }) : x))


            } else if (values?.mode === ChatMode.SUPPORT_AGENT && values.ticketId) {

                const response = values.user?.id ? await makeASendTicketMessageByUser(values.ticketId, text, []) : await makeASendTicketMessageByEmail(values.ticketId, text, values.user?.email)
                // await sleep(1000)
                setMessages(messages => messages.map(x => x.temporalId === temporalId ? ({ ...x, id: response.data?.message?.id, status: EStateGeneric.SUCCEEDED }) : x))
            }
        }
        catch (err) {
            console.error(err.response || err)
            setMessages(messages => messages.map(x => x.temporalId === temporalId ? ({ ...x, status: EStateGeneric.FAIL }) : x))

        }


    }



    //Primera carga
    useEffect(() => {
        const fn = async () => {
            setLoading(true)
            if (typeof window !== "undefined" && localStorage) {
                try {
                    const storeData = localStorage.getItem("chatData")

                    let data: IChatPayload

                    try {
                        if (!storeData) {
                            throw new Error("No se ha encontrado datos de sesión chat")
                        }
                        data = JSON.parse(storeData)
                        updateValues({
                            user: data?.user || null
                        })
                    } catch {

                        data = initialValues
                    }


                    try {
                        const res = await fetchSelfUser()

                        console.log(res.data)
                        const { user } = res.data
                        data.user = {
                            id: user.id,
                            fullName: `${user?.firstName} ${user?.lastName}`,
                            email: user.email,
                            avatar: user.avatar,
                            tags: user.tags,
                        }

                    } catch (err) {
                        await googleLogout()
                        console.log(err)
                        //Borramos la información si el usuario ya no está logueado en autogestiones
                        data.user = null
                        data.conversationId = null
                        setMessages([])
                        data.mode = ChatMode.HOME
                        updateValues(data)
                        if (values?.user?.id) {
                            throw err
                        }
                    }

                    try {
                        const res = data.user?.id ? await fetchAllTicketsByUser({
                            take: 10,
                            channel: ETicketChannel.CHAT
                        }) : data.user?.email ? await fetchAllTicketsByEmail(data.user?.email) : null

                        console.log(res?.data)
                        setTickets(res?.data.tickets || [])
                    } catch (err) {
                        console.error(err)
                    }

                    const checkIfConfirmateEmail = () => {
                        if (data?.user && !data?.user?.tags?.includes(EUserTags.EMAIL_VERIFIED))
                            return {
                                isOpen: false,
                                actionPage: EActionPage.CONFIRM_REGISTRATION_EMAIL
                            } as Partial<IChatPayload>

                        return {}
                    }
                    updateValues({
                        ...data,
                        ...checkIfConfirmateEmail()
                    })
                } catch (err) {
                    console.error(err)
                }
            }
            setLoading(false)
        }

        fn()
    }, [])

    /**
     * yo por ejemplo yo no siento importancia por elecciones, candidatos, etc. Siento que son fragmentos de una historia nada más
     */
    return (
        <ChatContext.Provider value={{
            updateValues,
            ...values,
            loading,
            messages,
            setMessages,
            isLogged,
            answer,
            setAnswer,
            tickets,
            setTickets,
            sendMessageByWS,
            setLoading,
            sendFileByWS,
            logout,
            setOnAfterLogging,
            onAfterLogging,
            showLoader,
            setShowLoader,
            targetCountry,
            countries
        }} >
            {children}
        </ChatContext.Provider>
    )
}

export const useChat = () => useContext(ChatContext)


function convertLinksToAnchors(text: any) {
    // Expresión regular para buscar enlaces
    var linkRegex = /(?:https?|ftp):\/\/[^\s/$.?#].[^\s]*/g;

    // Reemplazar los enlaces encontrados por enlaces HTML
    var convertedText = text.replace(linkRegex, function (match) {
        return `<a  class="dark-blue-link" target="_blank" href="${match}">${match}</a>`;
    });

    return convertedText;
}

/* 
import { useEffect, useMemo, useState } from "react";
import io from "socket.io-client";
import { ChatMode } from "./types";



export const useChat = (initialValues: IChatPayload) => {

    const [values, setValues] = useState<IChatPayload>(initialValues)

    const updateValues = (values: Partial<IChatPayload>) => {
        setValues({
            ...initialValues,
            ...values
        })
    }

    useEffect(() => {
        if (typeof window !== "undefined") {
            setValues({
                mode: localStorage.getItem("autoMode") || ChatMode.CHATBOT,
                conversationId: localStorage.getItem("autoConversationId") || null,
                ticketId: localStorage.getItem("autoTicketId") || null,
            } as any)
        }
    }, [])

    return {
        updateValues,
        ...values
    }
} */