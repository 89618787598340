
export enum ChatMode {
    CHATBOT = "chatbot",
    SUPPORT_AGENT = "support-agent",
    HOME = "home"
}

export enum ETicketStatus {
    ANSWERED = "ANSWERED",
    UNANSWERED = "UNANSWERED",
    CLOSED = "CLOSED",
}

export enum ETicketChannel {
    EMAIL = 'EMAIL',
    WEB = 'WEB',
    CHAT = 'CHAT',
  }export enum EUserTags {
	BANNED = "BANNED",
	PROGRAM_DELETED = "PROGRAM_DELETED",
	DATA_COMPLETE = "DATA_COMPLETE",
	TWO_FACTOR_AUTH = "TWO_FACTOR_AUTH",
  EMAIL_VERIFIED = "EMAIL_VERIFIED",
  FREE_TRIAL_USED = "FREE_TRIAL_USED",
}
