import   currencyjs from "currency.js"
import BMO from "./BMO";


const truncate = (input, max) =>
  input.length > max ? `${input.substring(0, max)}...` : input;

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

const validWebsiteRegex = RegExp(
  /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/i
);

const validPhoneRegex = RegExp(
  /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/i
);

export function eliminarDiacriticos(texto: string) {
  return texto?.normalize("NFD")?.replace(/[\u0300-\u036f]/g, "") || ""
}

export const normalizeText = (str: string) => {
  str = str?.toString()
  if (typeof str === "string") {
    const v1 = str.normalize("NFD").replace(/\p{Diacritic}/gu, "")
    const v2 = v1.toLowerCase()
    const v3 = v2.trim()
    return eliminarDiacriticos(v3)
  } else {
    return str
  }
}

export const tokenizeToAutocomplete = str => {
  const inputText = normalizeText(str)

  if (typeof inputText !== "string") {
    return ""
  }
  return inputText.replace(/[^a-zA-Z0-9]/g, "")
}
export function joinWithAnd(arr: string[]): string {
  if (arr.length === 0) {
    return ""
  }
  if (arr.length === 1) {
    return arr[0]
  }
  const last = arr.pop()
  return `${arr.join(", ")} y ${last}`
}
export enum EStateGeneric {
  IDLE = "idle",
  SUCCEEDED = "succeeded",
  PENDING = "pending",
  FAIL = "fail",
}
const validFullNameRegex = RegExp(/^[a-zA-Z]{4,}(?: [a-zA-Z]+){0,2}$/i);

const capitalize = (str, lower = false) =>
  (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) =>
    match.toUpperCase()
  );
export {
  truncate,
  validEmailRegex,
  validWebsiteRegex,
  validPhoneRegex,
  validFullNameRegex,
  capitalize,
};

export const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};


export const formatMoney2 = (amount: string | number, currency: any) =>
  `${currencyjs(amount || 0, {
    symbol: "$",
    precision: currency?.type === "CRYPTO" ? 8 : 2,
  }).format()} ${currency?.symbol}`


export const formatNumber = (_amount: string | number, decimalPlaces) => {
  const amount = isNaN(+_amount) || !isFinite(+_amount) ? 0 : _amount
  return currencyjs(BMO.roundByValueNumber(amount, decimalPlaces), {
    precision: decimalPlaces
  }).format({
    decimal: ",",
    separator: ".",
    symbol: "",
  });
}

export function removeNonAlphanumeric(input) {
  if (input === null || input === undefined) {
    return '';
  }

  // Convert the input to a string if it's a number
  const inputString = typeof input === 'number' ? input.toString() : input;

  // Regular expression that matches all characters that are not letters or numbers
  const regex = /[^a-zA-Z0-9]+/g;

  // Use the .replace() method with the regular expression to replace unwanted characters with an empty string
  const cleanedString = inputString.replace(regex, '');

  // Return the resulting cleaned string
  return cleanedString;
}


type NationalCurrency = { id: number }
export const formatMoney = (amount: string | number, currencyTarget, symbolMode: "empty" | "$" | "use_money_symbol" | NationalCurrency) => {
  let symbol = "$ "

  if (symbolMode === "$") {
    symbol = "$ "
  } else if (symbolMode === "empty") {
    symbol = ""
  } else if (symbolMode === "use_money_symbol") {
    symbol = `${currencyTarget?.symbol} `
  } else {
    const nationalId = symbolMode?.id
    if (currencyTarget?.symbol && nationalId !== currencyTarget?.id) {
      symbol = `${currencyTarget?.symbol} `
    } else {
      symbol = "$ "
    }
  }

  return currencyjs(BMO.roundByMoney(amount, currencyTarget) || 0, {
    symbol,
    precision: currencyTarget?.type === "CRYPTO" ? 8 : 2,
  }).format({
    decimal: ",",
    separator: ".",
  });
}
export const wrapErrorMessageText = (errors: any) => {
  console.log(errors);
  try {
    const text = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 1, 1].reduce((result, i) => {
      if (Array.isArray(result)) {
        return result?.filter((v) => v).filter((v) => isNaN(+v))[0];
      } else if (typeof result === "object") {
        return Object.values(result).filter((v) => isNaN(+v))[0];
      } else if (typeof result === "string") return result;
      if (result) return String(result);
    }, errors);
    console.log(errors);
    if (text) return text;
  } catch {
    return String(JSON.stringify(errors));
  }
};