import React from 'react';
import { createContext } from 'react';
import { useSocket } from './useSocket';
import { Socket } from "socket.io-client"
import { useGoogleOneTapLogin } from '@react-oauth/google';
import { signInWithGoogle } from '../Chat/api';

export interface ISocketContext {
    socket?: Socket
    online?: boolean
}

export const SocketContext = createContext({} as ISocketContext);


export const SocketProvider = ({ children }) => {

    const { socket, online } = useSocket(process.env.GATSBY_SOCKET_URL);
    
    return (
        <SocketContext.Provider value={{ socket, online }}>
            {children}
        </SocketContext.Provider>
    )
}