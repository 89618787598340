import axios from "axios";

const qs = require("qs");


const API = process.env.GATSBY_API_URL;

const configUpload = () => ({
    headers: {
        "content-type": "multipart/form-data",
    },
    withCredentials: true,
});




export const resendConfirmationLinkUser = async () => {
    return axios.post(
        `${API}/users/resend-confirmation-email`,
        {},
        { withCredentials: true }
    );
};


export const registerWithEmail = async ({
    dto,
}: {
    dto: any;
}) => {
    const result = await axios.post(
        `${API}/auth/register`,
        dto,
        {
            withCredentials: true,
        }
    );
    localStorage.setItem("isAuth", "true");

    return result;
};

export const signInWithEmail = async (email: string, password: string) => {
    const result = await axios.post(
        `${API}/auth/login`,
        { email, password },
        {
            withCredentials: true,
        }
    );

    localStorage.setItem("isAuth", "true");
    return result;
};


export const verifyUserEmailWithCode = async (code: string | number) => {
    return axios.post(
        `${API}/users/confirmate-email/${code}`,
        {},
        { withCredentials: true }
    );
};

export const authenticate2fa = async (twoFactorAuthenticationCode: string) =>
    axios.post(
        `${API}/2fa/authenticate`,
        { twoFactorAuthenticationCode },
        {
            withCredentials: true,
        }
    );
export const signInWithGoogle = async (body: { credential?: string; accessToken?: string, targetCountryId: string }) => {
    const result = await axios.post(`${API}/google-authentication/register`, body, {
        withCredentials: true,
    });

    localStorage.setItem("isAuth", "true");
    return result;
};

export const unsubscribeToNotifications = async (endpoints: string[]) =>
    await axios.post(
        `${API}/notifications/unsubscription`,
        { endpoints },
        {
            withCredentials: true,
        }
    );



export const fetchAllTicketsByUser = async (query: any) =>
    await axios.get(
        `${API}/tickets/details`,
        {
            params: query,
            withCredentials: true
        }
    );

export const fetchAllTicketsByEmail = async (email: string) =>
    await axios.get(
        `${API}/chats/tickets/guest`,
        {
            params: {
                email
            },
        }
    );

export const fetchSelfUser = async () =>
    await axios.post(
        `${process.env.GATSBY_API_URL}/auth`,
        {},
        { withCredentials: true }
    )


export const fetchAllCustomers = async () =>
    await axios.get(
        `${process.env.GATSBY_API_URL}/customers/details`,
        { withCredentials: true }
    )



export const fetchAllOrderStats = async () =>
    await axios.get(
        `${process.env.GATSBY_API_URL}/app-store/orders/stats/details`,
        { withCredentials: true }
    )


export const fetchTicketMessageHistoryByUser = async (ticketId: string) =>
    await axios.get(
        `${process.env.GATSBY_API_URL}/tickets/${ticketId}/messages/details`,
        {
            withCredentials: true,
            params: {
            }
        }
    )


export const fetchTicketMessageHistoryByEmail = async (ticketId: string, email: string) =>
    await axios.get(
        `${process.env.GATSBY_API_URL}/chats/tickets/${ticketId}/messages/guest`,
        {
            params: {
                email
            }
        }
    )

export const makeASendAttachmentByUser = async (ticketId: string, file) => {

    const FormData = require("form-data");
    const formData = new FormData();

    formData.append("files", file);
    formData.append("text", "");

    formData.append("isAnswer", "false");


    return await axios.post(
        `${process.env.GATSBY_API_URL}/tickets/${ticketId}/messages/details`,
        formData,
        {
            withCredentials: true,
            params: {
            }
        }
    )
}


export const makeASendTicketMessageByUser = async (ticketId: string, text: string, files) => {

    const FormData = require("form-data");
    const formData = new FormData();

    if (files)
        for (let index = 0; index < files?.length; index++) {
            const file = files[index];
            formData.append("files", file);
        }

    formData.append("text", text);
    formData.append("isAnswer", "false");


    return await axios.post(
        `${process.env.GATSBY_API_URL}/tickets/${ticketId}/messages/details`,
        formData,
        {
            withCredentials: true,
            params: {
            }
        }
    )
}


export const makeASendTicketMessageByEmail = async (ticketId: string, text: string, email: string) =>
    await axios.post(
        `${process.env.GATSBY_API_URL}/chats/tickets/${ticketId}/messages/guest`,
        {
            text,
            email
        }
    )



export const makeASendChatMessageByEmail = async (conversationId: string, text: string, email: string) =>
    await axios.post(
        `${process.env.GATSBY_API_URL}/chats/conversations/${conversationId}/messages/guest`,
        {
            text,
        },
        {
            params: {
                email
            }
        }
    )



export const makeASendChatMessageByUser = async (conversationId: string, text: string) =>
    await axios.post(
        `${process.env.GATSBY_API_URL}/chats/conversations/${conversationId}/messages/user`,
        {
            text,
        },
        {
            withCredentials: true
        }
    )

export const fetchChatHistoryByConversationId = async (id) =>
    await axios.get(
        `${API}/chats/conversations/${id}/messages/`,
        {
            withCredentials: true
        }
    );





export const makeACreateConversation = async () =>
    await axios.post(
        `${process.env.GATSBY_API_URL}/chats/conversations/`,
        {
        },
        {
            withCredentials: true
        }
    )
