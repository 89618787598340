import { SocketProvider } from './src/components/Socket/SocketContext'
import './src/global.css'
import "./src/static/css/all.min.css"
import moment from "moment";
import "moment/locale/es";
import React from 'react'
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Location } from "@reach/router"; // Import Location from @reach/router
import ChatContainer from './src/components/Chat/Chat';
import { ChatProvider } from './src/components/Chat/ChatContext';
import 'react-tooltip/dist/react-tooltip.css'

moment.locale("es");

const countries = [
  {
    id: "ARG",
    iso: "ar",
    name: "Argentina"
  },
  {
    id: "MEX",
    iso: "mx",
    name: "México"
  },
  {
    id: "URU",
    iso: "uy",
    name: "Uruguay"
  }
]

export const wrapRootElement = ({ element }) => {
  const isBrowser = typeof window !== 'undefined';

  return (
    <Location>
      {({ location }) => {
        // Verificar si la ruta es "/" y excluir SocketProvider en ese caso
        const isRootPath = location.pathname === "/";

        const arrPath = (location.pathname) ? location.pathname.split("/") : [];

        const iso = arrPath[1]; // Obtén el segundo elemento del array después de dividir la ruta

        const targetCountry = countries.find(x => x.iso === iso)

        if (location.pathname.startsWith("/planes")) {
          const defaultCountry =   {
            "id": "ARG",
            "name": "Argentina",
            "tags": [
              "ALLOW_USERS",
              "ALLOW_CUSTOMERS",
              "ALLOW_BILLINGS"
            ],
            "iso": "AR",
            "currency": {
              "id": 2,
              "name": "Pesos Argentinos",
              "symbol": "ARS",
              "type": "FIAT",
              "color": "#00b5d7"
            }
          }
          return <SocketProvider>
            <GoogleOAuthProvider clientId={"609624224178-4htd5kdi8dg9m15a1159cjbr22vsqc0t.apps.googleusercontent.com"}>
              <ChatProvider countries={countries}  targetCountry={defaultCountry}>
                {element}
                {isBrowser && <ChatContainer />}
              </ChatProvider>
            </GoogleOAuthProvider>
          </SocketProvider>
        }

        let content = <div>
          {element}
        </div>

        if (targetCountry) {
          content =
            <ChatProvider countries={countries} targetCountry={targetCountry}>{element}
              {isBrowser && <ChatContainer />}
            </ChatProvider>
        }

        if (!isRootPath && !String(location.pathname).startsWith("/i")) {
          return <SocketProvider>
            <GoogleOAuthProvider clientId={"609624224178-4htd5kdi8dg9m15a1159cjbr22vsqc0t.apps.googleusercontent.com"}>
              {content}
            </GoogleOAuthProvider>
          </SocketProvider>
        }

        return <>
          {element}
        </>
      }}
    </Location>
  );
}

