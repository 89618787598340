import * as _ from "lodash"
import { CurrencyType } from "../components/Billing/types";
 interface RoundOptions {
    decimalPlaces: number;
    roundType: 'RoundHalfUp' | 'RoundHalfDown' | 'RoundHalfEven';
    currencyType: CurrencyType
}

class BillingManager {
    private decimalPlaces: number;
    private roundType: 'RoundHalfUp' | 'RoundHalfDown' | 'RoundHalfEven';
    constructor(options: RoundOptions = { decimalPlaces: 5, currencyType: CurrencyType.FIAT, roundType: 'RoundHalfEven' }) {
        this.decimalPlaces = options.decimalPlaces;
        this.roundType = options.roundType;
    }

    roundNumber(number) {
        return BillingManager.roundNumberStatic(number, this.roundType, this.decimalPlaces);
    }

    //FRONTEND 
    static roundByMoney(number, currency: { type: CurrencyType },) {
        const roundType = currency?.type === CurrencyType.CRYPTO ? "RoundHalfDown" : "RoundHalfEven"
        const decimalPlaces = currency?.type === CurrencyType.CRYPTO ? 8 : 2
        return BillingManager.roundNumberStatic(number, roundType, decimalPlaces);
    }

    static roundByValueNumber(number, _decimalPlaces) {
        const roundType = "RoundHalfEven"
        return BillingManager.roundNumberStatic(number, roundType, _decimalPlaces);
    }



    static calculateSubtotalItem(item): number {
        const quantity = (_.get(item, 'quantity', 0));
        const price = (_.get(item, 'price', 0));
        const discountPercent = (_.get(item, 'discountPercent', 0));

        const discount = (1 - (discountPercent / 100))
        return quantity * price * discount
        /* const subtotal = currencyjs(quantity, { precision: 10 })
            .multiply(price)
            .multiply(1 - (discountPercent / 100));

        return subtotal.value; */

    }

    static calculateOneSubtotalItemVat(item): number {
        const aliquot = _.get(item, 'vatAssoc.aliquot', 0)

        const multiplier = +aliquot / (100 + +aliquot) || 0
        const subtotal = BillingManager.calculateSubtotalItem(item)
        return subtotal * multiplier
    }

    static calculateOneSubtotalItemWithoutVat(item): number {
        const vat = BillingManager.calculateOneSubtotalItemVat(item)
        const subtotal = BillingManager.calculateSubtotalItem(item)
        return subtotal - vat
    }

    static calculateAllSubtotalItemsVat(item): number {
        return _.sum(item.map(this.calculateOneSubtotalItemVat).filter(x => +x > 0))
    }


    static calculateAllSubtotalItems(items: any[]): number {
        return _.sum(items.map(BillingManager.calculateSubtotalItem).filter(x => +x > 0))
    }

    static calculateAllSubtotalItemsWithoutVat(items: any[]): number {
        const a = +BillingManager.calculateAllSubtotalItems(items)
        const b = +BillingManager.calculateAllSubtotalItemsVat(items)
        return a - b
    }

    static calculateSubtotalFees(fees: any[]): number {
        return _.sum(fees.map(x => x.subtotal).filter(x => +x > 0))
    }

    static calculateTotal(billing: any): number {
        return _.sum([this.calculateSubtotalFees(billing.fees), this.calculateAllSubtotalItems(billing.items)])
    }


    static roundNumberStatic(number, roundType, decimalPlaces): number {
        const multiplier = 10 ** decimalPlaces;
        switch (roundType) {
            case 'RoundHalfUp':
                return Math.round(number * multiplier + 0.5) / multiplier;
                break;
            case 'RoundHalfDown':
                return Math.round(number * multiplier - 0.5) / multiplier;
                break;
            case 'RoundHalfEven':
                return Math.round(number * multiplier) / multiplier;
                break;
            default:
                return Math.round(number * multiplier) / multiplier;
        }

    }


    roundInvoice(invoice: any): void {
        if (invoice.hasOwnProperty('total')) {
            invoice.total = this.roundNumber(parseFloat(invoice.total));
        }
    }

    convertToNationalCurrency(invoice: any, currency: { id: string, name: string, symbol: string, quoteCurrency: any }): void {
        invoice.currencyId = currency?.id
        invoice.currency = currency

        if (invoice.hasOwnProperty('total')) {
            invoice.total = this.roundNumber(parseFloat(invoice.total) * currency?.quoteCurrency);
        }

        if (invoice.hasOwnProperty('total')) {
            invoice.total = this.roundNumber(parseFloat(invoice.total) * currency?.quoteCurrency);
        }

        if (invoice.hasOwnProperty('items') && Array.isArray(invoice.items)) {
            for (const item of invoice.items) {
                if (item.hasOwnProperty('subtotal')) {
                    item.subtotal = this.roundNumber(parseFloat(item.subtotal) * currency?.quoteCurrency);
                }

                if (item.hasOwnProperty('price')) {
                    item.price = this.roundNumber(parseFloat(item.price) * currency?.quoteCurrency);
                }
            }


        }

        if (invoice.hasOwnProperty('fees') && Array.isArray(invoice.fees)) {

            for (const fee of invoice.fees) {
                if (fee.hasOwnProperty('subtotal')) {
                    fee.subtotal = this.roundNumber(parseFloat(fee.subtotal) * currency?.quoteCurrency);
                }
            }

        }

    }

    static calculateWithoutVat = (items: any[], aliquotPercent): number => {
        return _.sum(items
            .filter((item) => +item?.vatAssoc?.aliquot === +aliquotPercent)
            .map((item) => {


                const subtotal = BillingManager.calculateOneSubtotalItemWithoutVat(item)

                
                return subtotal
            }, 0)
            .filter(x => +x > 0 && Number.isFinite(+x))
        );
    };

    static calculateVat = (items: any[], aliquotPercent): number => {
        return _.sum(items
            .filter((item) => +item?.vatAssoc?.aliquot === +aliquotPercent)
            .map((item) => {


                const subtotal = BillingManager.calculateOneSubtotalItemVat(item)

                return subtotal
            }, 0)
            .filter(x => +x > 0 && Number.isFinite(+x))
        );
    };

    static calculateSubtotalByVatId = (billing, id: number): number => {
        return _.sum(billing.items
            .filter((item) => +item.vatAssoc?.id === +id)
            .map(item => BillingManager.calculateSubtotalItem(item))
        )
    };

}

export default BillingManager

/*  getInvoiceNetoNoGravado(invoice: any): number {
        // Asume que los ítems tienen un campo 'isGravado' que indica si están gravados o no.
        if (invoice.hasOwnProperty('items') && Array.isArray(invoice.items)) {
            let netoNoGravado = 0;
            for (const item of invoice.items) {
                if (item.hasOwnProperty('subtotal') && !item.isGravado) {
                    netoNoGravado += parseFloat(item.subtotal);
                }
            }
            return this.roundNumber(netoNoGravado);
        }
        return 0;
    }
 
    getItemNetoGravado(invoice: any): number {
        // Asume que los ítems tienen un campo 'isGravado' que indica si están gravados o no.
        if (invoice.hasOwnProperty('items') && Array.isArray(invoice.items)) {
            let netoNoGravado = 0;
            for (const item of invoice.items) {
                if (item.hasOwnProperty('subtotal') && !item.isGravado) {
                    netoNoGravado += parseFloat(item.subtotal);
                }
            }
            return this.roundNumber(netoNoGravado);
        }
        return 0;
    } */